import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Fragment } from 'react';
import { graphql } from 'gatsby';
import { jsx, Grid, Embed, AspectRatio } from "theme-ui";
import { fontSize } from '../gatsby-plugin-theme-ui/tokens';
import { PageLayout } from "../layouts/PageLayout";
import { TweetDiscussEditLinks } from '../features/social-sharing/TweetDiscussEditLinks';
export const pageQuery = graphql`
  query MusicPageQuery($path: String!) {
    sitePage(path: {eq: $path}) {
      ...TweetDiscussEditLinksDataOnSitePage
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageLayout mdxType="PageLayout">
      <main>
        <h1 {...{
          "id": "music",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#music",
            "aria-label": "music permalink",
            "className": "remark-autolink-headers__anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "viewBox": "0 0 24 24",
              "fill": "none",
              "stroke": "currentColor",
              "strokeWidth": "2"
            }}><path parentName="svg" {...{
                "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
              }}></path><path parentName="svg" {...{
                "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
              }}></path></svg></a>{`Music`}</h1>
        <p>{`I find these are quite cool.`}</p>
        <Grid columns={[1, 2]} mdxType="Grid">
  {["https://www.youtube.com/embed/jrTMMG0zJyI", "https://www.youtube.com/embed/videoseries?list=PLOgab_kIovBZWXHes9fxPZPtJ0ExxuA1p", "https://www.youtube.com/embed/i84L16VL6c8", "https://www.youtube.com/embed/PTRbKJdd5CQ", "https://www.youtube.com/embed/r6pqu9t0WrQ", "https://www.youtube.com/embed/ShJLmGkOTXc", "https://www.youtube.com/embed/kZOq2rQrTMo", "https://open.spotify.com/embed/track/3zKqcF6TkumEY3Ue2WCKjU", "https://open.spotify.com/embed/track/6Hr77eZSmaVGgH0vVulMUH", "https://open.spotify.com/embed/track/086QQrq148XbaGFlRsglTG", "https://open.spotify.com/embed/track/6B6ZmZ5VMRCxIiDPO7ar77", "https://open.spotify.com/embed/track/4sFzG7iUlyPmuyASCkre9A", "https://open.spotify.com/embed/track/0zJMB0pn2EyHBATj0dvGVY", "https://open.spotify.com/embed/track/6RuVGp9Gh2f5iEkHUR5xdC"].map(src => <AspectRatio ratio={4 / 3} key={src} mdxType="AspectRatio">
      <Embed sx={{
              height: '100%',
              bg: 'muted'
            }} src={src} mdxType="Embed" />
    </AspectRatio>)}
        </Grid>
        <div style={{
          height: '3.5em'
        }} />
        <Box sx={{
          my: 3,
          fontSize: fontSize.small
        }} mdxType="Box">
          <p>{`See past versions of this page:`}</p>
          <ul>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://5f4839a0f41208000737334d--hasparus.netlify.app/music"
              }}>{`March 28th to Aug 28th 2020`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://5e7c7848bd5c980007680613--hasparus.netlify.com/music/"
              }}>{`Before March 27th 2020`}</a></li>
          </ul>
        </Box>
        <TweetDiscussEditLinks socialLinks={props.data.sitePage.socialLinks} mdxType="TweetDiscussEditLinks" />
      </main>
    </PageLayout>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      